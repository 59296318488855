import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField, Link } from "@mui/material";
import logo from "../assets/Image/logo.svg";
// import { Icon } from "@iconify/react";
import { getCurrentUser, signInWithRedirect, signOut } from "aws-amplify/auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { signIn } from "aws-amplify/auth";
import { useMyContext } from "../context";
import { Loader } from "../Components/SpinnerLoading";

function Login() {
  const navigate = useNavigate();
  const { setIsLoading } = useMyContext();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName) {
      navigate("/dashboard");

      getCurrentUser().catch(() => {
        localStorage.removeItem("userName");
        setIsLoading(false);
        navigate("/");
      });
    } else {
      signOut();
    }
    setIsChecking(false);
  }, []);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);

      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const { nextStep } = await signIn({
        username: data.get("email"),
        password: data.get("password"),
      });
      setIsLoading(false);

      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        alert("User is not confirmed.");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alert(error?.response?.data?.error || error);
    }
  };

  if (isChecking) {
    return <Loader />;
  }

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          mt: 8,
        }}
      >
        <img
          src={logo}
          alt=""
          style={{
            width: "130px",
            height: "130px",
            display: "block",
            margin: "auto",
          }}
        />
        <Typography
          sx={{
            mt: 10,
            fontSize: "26px",
            fontWeight: 800,
            color: "#000000",
            opacity: 1,
            textAlign: "center",
          }}
        >
          Choose the perfect
          <br />
          wine, every time.
        </Typography>
      </Box>
      <Box mt={10} sx={{ textAlign: "center" }}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="success"
          >
            Sign In
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Link
              to="/signup"
              variant="body2"
              component={RouterLink}
              color="#017101"
            >
              {"Don't have an account? Sign Up"}
            </Link>
            <Link
              to="/forgotPassword"
              variant="body2"
              component={RouterLink}
              color="#017101"
              sx={{ mt: 2 }}
            >
              Forgot password
            </Link>
          </Box>
        </Box>
        {/* <Button
            variant="outlined"
            sx={{
              width: "100%",
              border: "2px solid rgba(226, 232, 240, 1)",
              borderRadius: "50px",
              px: 3,
              py: 2,
              fontSize: "16px",
              fontWeight: 500,
              color: "#1e293b",
              opacity: 1,
              textTransform: "capitalize",
              "&:hover": {
                border: "2px solid rgba(30, 41, 59, 1)",
              },
            }}
            startIcon={<Icon icon="devicon:apple" style={{ fontSize: "20px" }} />}
            onClick={() => signInWithRedirect({ provider: "Apple" })}
          >
            Sign in with Apple
          </Button> */}

        {/* <Button
            variant="outlined"
            sx={{
              mt: 2,
              mb: 8,
              width: "100%",
              border: "2px solid rgba(226, 232, 240, 1)",
              borderRadius: "50px",
              px: 3,
              py: 2,
              fontSize: "16px",
              fontWeight: 500,
              color: "#1e293b",
              opacity: 1,
              textTransform: "capitalize",
              "&:hover": {
                border: "2px solid rgba(30, 41, 59, 1)",
              },
            }}
            startIcon={
              <Icon icon="flat-color-icons:google" style={{ fontSize: "20px" }} />
            }
            onClick={() => signInWithRedirect({ provider: "Google" })}
          >
            Sign in with Google
          </Button> */}
      </Box>
    </Box>
  );
}

export default Login;
