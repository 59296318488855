import React, { createContext, useContext, useEffect, useState } from "react";
import SpinnerLoading from "../Components/SpinnerLoading";
import * as queries from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { getUrl } from "aws-amplify/storage";

const Context = createContext();
const client = generateClient();

export const CtxProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null); // do not change this
  const [allowUpload, setAllowUpload] = useState(false);
  const [AIModel, setAIModel] = useState("gpt-4o-mini");
  const [historyData, setHistoryData] = useState([]);

  const fetchHistory = async (showLoader = true) => {
    try {
      if (showLoader) setIsLoading(true);

      const { data } = await client.graphql({
        query: queries.listUerWineHistories,
        variables: { filter: { userId: { eq: user?.userId } }, limit: 30000 },
      });
      const { items: itemsPage } = data.listUerWineHistories;
      const dataWithURi = await Promise.all(
        itemsPage
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 10)
          .map(async (item) => {
            const wineImg = await getUrl({
              key: item.wineImg,
              options: { expiresIn: 21600 },
            });
            return {
              ...item,
              wineImg: wineImg.url.href,
            };
          })
      );
      setHistoryData(dataWithURi);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const val = localStorage.getItem("allowUpload");
    setAllowUpload(Boolean(val));
  }, []);

  return (
    <Context.Provider
      value={{
        setIsLoading,
        isLoading,
        user,
        isAuthenticated,
        setIsAuthenticated,
        setUser,
        allowUpload,
        setAllowUpload: (val) => {
          localStorage.setItem("allowUpload", val);
          setAllowUpload(val);
        },
        setAIModel,
        AIModel,
        historyData,
        fetchHistory,
      }}
    >
      {children}
      <SpinnerLoading />
    </Context.Provider>
  );
};

export const useMyContext = () => useContext(Context);
