import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  Typography,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { useMyContext } from "../context";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#017101",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Menu() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { allowUpload, setAllowUpload, AIModel, setAIModel } = useMyContext();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut({ global: true }); // Call signOut with the appropriate provider
      localStorage.removeItem("userName");
      navigate("/"); // Navigate to home after successful logout
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          color: "rgba(1, 113, 1, 1)",
          textAlign: "end",
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <MenuIcon fontSize="large" onClick={toggleDrawer} />
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paperAnchorRight": {
            marginTop: "env(safe-area-inset-top)",
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <Box>
            <ListItem sx={{ display: "block" }}>
              <ListItemButton onClick={() => navigate("/myTestProfile")}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#000000",
                    opacity: 0.9,
                    fontWeight: 500,
                  }}
                >
                  My taste profile
                </Typography>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/userProfile")}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#000000",
                    opacity: 0.9,
                    fontWeight: 500,
                  }}
                >
                  User profile
                </Typography>
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/favoriteList")}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#000000",
                    opacity: 0.9,
                    fontWeight: 500,
                  }}
                >
                  Favourite list
                </Typography>
              </ListItemButton>
              {/* <ListItemButton>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#000000",
                    opacity: 0.9,
                    fontWeight: 500,
                  }}
                >
                  GPT4-mini
                </Typography>
                <IOSSwitch
                  sx={{ ml: 1 }}
                  checked={AIModel === "gpt-4o-mini"}
                  onChange={(e) =>
                    e.target.checked
                      ? setAIModel("gpt-4o-mini")
                      : setAIModel("gpt-4o")
                  }
                />
              </ListItemButton> */}
              <ListItemButton>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#000000",
                    opacity: 0.9,
                    fontWeight: 500,
                  }}
                >
                  Photo upload
                </Typography>
                <IOSSwitch
                  sx={{ ml: 1 }}
                  checked={allowUpload}
                  onChange={(e) => setAllowUpload(e.target.checked)}
                />
              </ListItemButton>
            </ListItem>
          </Box>
          <Divider />
          <Box>
            <Box>
              <Button
                onClick={handleLogout}
                variant="text"
                sx={{
                  paddingLeft: "35px",
                  textTransform: "capitalize",
                  fontSize: "20px",
                  color: "#000000",
                  opacity: 0.9,
                  fontWeight: 500,
                }}
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default Menu;
