/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_appsync_graphqlEndpoint:
    "https://znsfkvklbfhgxfjczuysjmp2cu.appsync-api.ap-southeast-2.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-3dwb72qxbrgnnckxnbfz2xymre",
  aws_cognito_identity_pool_id:
    "ap-southeast-2:384c76bf-f47a-43bd-ab31-a2adb9049386",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_npjL0uGMv",
  aws_user_pools_web_client_id: "vpo2apknnqlnqmpl988tpkfpm",
  oauth: {
    domain:
      "heysommc69f1db2-c69f1db2-prod.auth.ap-southeast-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "heysomm://localhost/",
    redirectSignOut: "heysomm://localhost/",
    // "redirectSignIn": "https://heysomm.ai/dashboard/,http://localhost:3000/dashboard/",
    // "redirectSignOut": "https://heysomm.ai/,http://localhost:3000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL", "GIVEN_NAME", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket:
    "heysomm8724f6d73e6d47ae980e2be44fb58985163351-prod",
  aws_user_files_s3_bucket_region: "ap-southeast-2",
};

export default awsmobile;
