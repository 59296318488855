import {
  Box,
  Typography,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Switch,
} from "@mui/material";
import Menu from "../Components/Menu";
import BottomNavigationButton from "../Components/BottomNavigationButton";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Browser } from "@capacitor/browser";
import { styled } from "@mui/material/styles";
import { useMyContext } from "../context";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import DeleteAccount from "../Components/DeleteAccount";

const client = generateClient();

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#017101",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function UserProfile() {
  const tasteProfiles = [
    "Standard",
    "Casual",
    // "Bourdain",
    // "Hemingway",
    // "Plath",
    "Poetic",
    "Gen Z",
  ];

  const navigate = useNavigate();
  const [selectedProfile, setSelectedProfile] = useState(null);
  const { setIsLoading, user, allowUpload, setAllowUpload, setUser } =
    useMyContext();

  useEffect(() => {
    if (user?.userId) {
      setIsLoading(true);
      client
        .graphql({
          query: queries.getUser,
          variables: { id: user.userId },
        })
        .then(({ data }) => {
          const attitude = data.getUser.attitude;
          setSelectedProfile(attitude);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [user]);

  const saveDetailsDB = async (profile) => {
    try {
      setIsLoading(true);
      const todoDetails = {
        id: user.userId,
        attitude: tasteProfiles.find((item) => item === profile),
      };
      const dd = await client.graphql({
        query: mutations.updateUser,
        variables: { input: todoDetails },
      });
      setUser({ ...user, ...dd.data.updateUser });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleButtonClick = (profile) => {
    setSelectedProfile(profile);
    saveDetailsDB(profile);
  };

  return (
    <Box sx={{ px: 2.5, py: 3 }}>
      <Box>
        <Menu />
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 400,
              color: "#017101",
              opacity: 0.9,
            }}
          >
            Profile and settings
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              mt: 5,
              ml: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Button
              variant="text"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                textTransform: "inherit",
              }}
              onClick={() => navigate("/myTestProfile")}
            >
              My taste profile
            </Button>
            <Button
              variant="text"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                textTransform: "inherit",
              }}
            >
              Photo upload
              <IOSSwitch
                sx={{ ml: 2 }}
                checked={allowUpload}
                onChange={(e) => setAllowUpload(e.target.checked)}
              />
            </Button>
            <Box>
              <DeleteAccount />
            </Box>
            <Button
              variant="text"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                textTransform: "inherit",
              }}
              onClick={() =>
                Browser.open({ url: "https://www.hellopraxis.com/privacy" })
              }
            >
              Privacy Policy
            </Button>
          </Box>

          <Box>
            <Typography
              sx={{
                mt: 1,
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                ml: 2,
                textTransform: "capitalize",
              }}
            >
              Attitude
            </Typography>
            <Box>
              <List>
                {tasteProfiles.map((profile, index) => {
                  return (
                    <ListItemButton
                      onClick={() => handleButtonClick(profile)}
                      sx={{ pl: 3 }}
                      key={index}
                      selected={selectedProfile === profile}
                    >
                      <ListItemIcon
                        sx={{ minWidth: "40px", color: "#017101", opacity: 1 }}
                      >
                        {selectedProfile === profile && <DoneIcon />}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body1">{profile}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          </Box>
        </Box>
        <BottomNavigationButton />
      </Box>
    </Box>
  );
}

export default UserProfile;
